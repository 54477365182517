body{
    /*font-family: Ubuntu, serif;*/
    overflow-x: hidden;
    background-color: #FAFAFA !important;
    /**/
}

.text-theme-light{

}

.nav-link{
    text-decoration: none;
    color: white !important;
}
.para,h4{
    /*color: #0061aa !important;*/
    /*color: #014375 !important;*/
    font-weight: bolder !important;
}
.bg-image {
    /*background-image: url(../img/_slide01.png) !important;*/
    /*background-color: #033973 !important;*/
    /*background-color: #0560a8 !important;*/
    /*background-color: #293a4a !important;*/
    /*background-image: url(../img/29313899_231086240784515_3487181503509561344_n.jpeg) !important;*/
    /*background-image: url(../img/29313899_231086240784515_3487181503509561344_n.jpeg) !important;*/
    /*background-color: #4d0082 !important;#f9f9f9*/
    background-color: #f9f9f9 !important;
    background-size: cover;
    background-position: center;
    height: 100vh;
}

table, tr, td{
    text-align: center;
}
/*
.card-header{
    background-color:#03355a !important;
}
.card-header h4{
    color: white !important;
}
*/

.custom-card {
    
    background: transparent !important;
    /*background-color: rgba(67, 67, 67, 0.82); */
    /* Réglez l'opacité selon vos préférences */
    padding: 20px; /* Ajoutez un espacement intérieur si nécessaire */
    /**border-bottom: 9px solid #011b2f;
    border-right: 6px solid #011b2f;**/
}


.bg-theme{
    /*background-image: linear-gradient(90deg, $theme-color, $theme-color2,$theme-color3);*/
    /*background-image: linear-gradient(90deg, #065947, #05d3a7, #088b6f) !important;*/
    /*background-color: #08193e !important;*/
    background-color: white !important;
    box-shadow: 0 2px 20px rgba(0,0,0,.2);
}

.text-silver{
    color: $silver;
}

.text-theme-light{
    color: $theme-color-light !important;
}

.sb-sidenav .sb-sidenav-footer{
    background: #4C0082 !important;
    box-shadow: none !important;
}

.navbar-expand .navbar-nav .nav-link{
    color: gray !important
}
.bg-theme-basic{
    /**background: $theme-color;**/
    /*background-image: linear-gradient(130deg, $theme-color, $theme-color2,$theme-color3);*/
    /*background-image: linear-gradient(1deg, #122622, #05d3a7, #088b6f) !important;*/
    background-color: #4C0082 !important;
}

p, h2, h2, h3, h4, h5, h6, address, ul{
    margin: 0;
    padding: 0;
}

a{
    text-decoration: none;
}

.nav-link{
    color: white;
}

.nav-link:hover{
    color: white;
    &:hover{
        color: $silver;
    }
    &:focus{
        color: $silver;
    }
}
/*
.btn-warning,.btn-warning:hover{
    background: transparent !important;
    border: 1px solid #428bca;    
    color: #428bca;
    border-radius: 2px;
    margin: 2px 5px 2px 5px;
    text-decoration: none;
}*/
.btn-warning, .btn-warning:hover {
    background: #1271c3 !important;
    border: 1px solid #1271c3;
    color: #ffffff;
    border-radius: 2px;
    margin: 2px 5px 2px 5px;
    text-decoration: none;font-weight: 700;
}

.btn-danger, .btn-danger:hover {
    background: #9d0505 !important;
    border: 1px solid #428bca;
    color: #ffffff;
    border-radius: 2px;
    margin: 2px 5px 2px 5px;
    text-decoration: none;font-weight: 700;
    text-decoration: none;
}
/*
.btn-danger,.btn-danger:hover{
    background: transparent !important;
    border: 1px solid #428bca;    
    color: #428bca;
    border-radius: 2px;
    margin: 2px 5px 2px 5px;
    text-decoration: none;
}*/

.sb-sidenav-menu-heading{
    color: #9E9E9E;
}

.modal-header{
    padding: .25rem 1rem;
    /*background-image: linear-gradient(90deg, $theme-color, $theme-color-light,$theme-color);*/
    background-color: #607d8b !important;
    color: white;
}

.section-gap{
    margin-top: 100px;
    margin-bottom: 100px;
}

/*
.section-headline{
    color: $theme-color;
    margin-bottom: 50px;
}
*/


.text-justify{
    text-align: justify;
}


/*
.bottom-nav{
    padding: 0;
    text-align: center;
}
*/


.zoom-able{
    transform: all .3s ease-in-out;
    &:hover{
        transform: scale(1.05);
    }
}

.aspect-one{
    aspect-ratio: 1/1 !important;
    object-fit: cover;
}
.aspect-two{
    aspect-ratio: 1/1.25 !important;
}

.text-theme{
    /*color: $theme-color;*/
}

.btn3{
    background-color:#3989cf !important;
    border: 1px solid #2970b0 !important;
    color: white;
  }

  .btn3:hover{
    background-color:#607d8b !important;
    border-color: #607d8b !important;
    color: white;
  }

  .btn4 {
    /* background-color: #102752 !important; */
    background-color: #1370c3!important;
    /*background-color: #FFC107 !important;*/
    border-color:#1370c3!important;
    font-size: 18px !important;
    font-weight: bolder;
    color: white !important;
}

/*
.btn4{
    background-color:#eee !important;
    border-color:#ccc !important;

    font-size: 18px !important;
    font-weight: bolder;    
  }
*/

  .btn5,.btn5:hover{
    background-color:white !important;
    box-shadow: 0 2px 1px rgba(0, 0, 0, 0.2);
    border: 1px solid #cecece!important;
    font-weight: bolder;
    font-size: 18;
    border-radius: 20;
    color: rgb(71, 70, 70);
  }

  .btn4:hover{
    background-color:#607d8b !important;
    border-color: #607d8b !important;
    color: white;
  }

.theme-button3,.theme-button3:hover{
    /*background: $theme-color;*/
    background-color:#607d8b !important;
    border: 1px solid #607d8b !important;
    font-size: 18px !important;
    font-weight: bolder;
    color: $white;
    @include transition;
    a{
        color: $white;
    }
    &:hover{
        a{
            color: $theme-color !important;
        }
        background-color:  #054874 !important;
        border-color:   #054874 !important;
    }
}

.add-files{
    /*background: $theme-color;*/
    background-color:  #4fb5ba !important;
    border-color:  #4fb5ba !important;
    font-size: 18px !important;
    font-weight: bolder;
    color: $white;
    @include transition;
    a{
        color: $white;
    }
    &:hover{
        a{
            color: $theme-color !important;
        }
        background-color:  #607d8b !important;
        border-color:   #607d8b !important;
    }
}

.theme-button2{
    /*background: $theme-color;*/
    background-color:#607d8b !important;
    border: 1px solid #607d8b;
    font-size: 18px !important;
    font-weight: bolder;
    color: $white;
    @include transition;
    a{
        color: $white;
    }
    &:hover{
        a{
            color: $theme-color !important;
        }
        background-color:#607d8b !important;
        border: 1px solid #607d8b;
    }
}

.theme-button{
    /*background: $theme-color;*/
    background-color:#607d8b !important;
    border: 1px solid #607d8b;
    color: $white;
    @include transition;
    a{
        color: $white;
    }
    &:hover{
        a{
            color: $theme-color !important;
        }
        background-color:#607d8b !important;
        border: 1px solid #607d8b;
    }
}

label{
    position: relative;
    .login-error-msg{
        position: absolute;
        color: red;
        text-shadow: 0 0 8px $white;
    }
}


.my-table{
    font-size: 14px;
    th, td{
        vertical-align: middle;
    }
}

.table-image{
    width: 75px;
    cursor: pointer;
}


.loader{
    width: 100%;
    height: 250px;
    position: relative;
    img{
        position: absolute;
        @include center;
    }
}

.soft-landing{
 @include transition;
}

.no-data-found-container{
    width: 100%;
    min-height: 100px;
    .no-data-found{
        position: absolute;
    }
}

/* Ajoutez ces styles à votre fichier CSS */
