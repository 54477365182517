@import "variables";
@import "mixin";
@import "global";

.login_btn {
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.45) !important;
    /*background-color: #ffc100 !important;*/
    background-color: #117aab !important;
    border-color: #189bd7 !important;
    /*color: black;*/
    color: white;
    font-weight: bolder;
}

.form-control,select.form-select.form-select-sm,.theme-button{
    border: 1px solid #cecece !important;
    font-size: 16px;
    font-weight: bolder;
}

.theme-button:hover{
    background-color: #102752 !important;
    border:1px solid #2196F3;
    color: white;    
    /*background-color: #0061aa !important;*/
    /*background-color: #ffc100 !important;*/
    /*color: white !important;*/
}

input[type='password']{
    font-family: Consolas,Monaco,monospace;
}

#login{
    width: 100vw;
    height: 100vh;
    position: relative;
    .card{
        /*width: 90%;*/
        @include center;
        /*box-shadow: 0 0 25px white;*/
        border: none;
        top: 44% !important;
        
        label, h4{
            color: $white;
            position: relative;
            .login-error-msg{
                position: absolute;
                color: red;
                text-shadow: 0 0 8px #8e8684;
                font-weight: bolder;
            }
        }
    }
}



.search-area{
    label{
        font-size: 14px;
        color: $theme-color;
        margin-bottom: 5px;
    }
}

/* styles.css */

/* styles.css */

@keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.05);
    }
    100% {
      transform: scale(1);
    }
  }
  
  .pulse {
    animation: pulse 1s infinite;
  }
  
  

.small-button{
    border:none;
    background: $theme-color;
    border-radius: 5px;   
    padding: 2px 6px;
    color: white;
    @include transition;
    &:hover{
        background: $theme-color-light;
    }
}

.value-container-parent{
    width: 312px;
    display: flex;
    .value-container{
        position: relative;
        //height: 30px;
        width: 100px;
        background: $theme-color;
        margin: 2px;
        border-radius: 5px;
        text-align: center;
        color: white;
        line-height: 30px;
        font-weight: bolder;
        cursor: pointer;
        &:hover .value-buttons{
            display: block;
            @include transition;
        }
        .value-buttons{
            @include center;
            @include transition;
            width: 100px;
            display: none;
            button{
                border: 0;
                padding: 2px 6px 1px 6px;
                font-size: 13px;
                color: $white;
                border-radius: 3px;
                @include transition;
                margin:1px;
                &:hover{
                    color:black;
                }
            }
        }
    }
}
